import { json, LoaderFunction } from "@remix-run/node";
import { DEFAULT_LANGUAGE } from "~/i18n";
import i18next from "~/i18next.server";
import { RemoteConfig } from "~/old-app/api/remote-config";
import { seoApis } from "~/old-app/api/seo-apis";
import { seoGenerator } from "~/old-app/utils/helpers/seo-generator";
import Home from "~/old-app/views/Home";

interface LoaderData {
  remoteConfig?: RemoteConfig | null;
  url: URL;
  translations: {
    [key: string]: string;
  };
  lang: string;
}

export const loader: LoaderFunction = async ({ params, request, }) => {

  const url = new URL(request.url);
  const lang = params?.lang || DEFAULT_LANGUAGE;
  const t = await i18next.getFixedT(lang);

  const remoteConfig = await seoApis.getHomeMeta(request) as RemoteConfig;

  const title = "t(`seo.homeTitle`);";
  const description = t(`seo.homeDescription`);

  const translations = {
    title,
    description,
  };

  return json<LoaderData>({
    url,
    translations,
    lang,
    remoteConfig,
  });
};

export const meta = ({ data }: { data: LoaderData }) => {
  if (!data) return null;

  const seo = seoGenerator({
    url: data?.url,
    title: data?.remoteConfig?.title || data?.translations?.title,
    description:
      data?.remoteConfig?.description || data?.translations?.description,
    seo_keywords: data?.remoteConfig?.keywords,
    lang: data?.lang,
  });

  return seo;
};



export default Home;


